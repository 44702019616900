.btn {
  @extend .text-uppercase;
  @extend .prelo-medium;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  letter-spacing: 0.2pt;

  @include media-breakpoint-down(xs) {
    font-size: 12px;
  }

  &:hover,
  &:focus {
    box-shadow: none;
  }

  &.btn-gotop {
    background: $dark;

    &:hover,
    &:focus {
      background: $primary;
    }
  }
}

[class*='btn-']:not([disabled]):not(.disabled).active,
[class*='btn-']:not([disabled]):not(.fill-hover),
[class*='btn-']:not([disabled]):not(.disabled):active,
.show > [class*='btn-'].dropdown-toggle,
[class*='btn-']:not([disabled]):not(.disabled):active {
  box-shadow: none;
}

// Outline button
[class*='btn-outline-'] {
  box-shadow: inset 0px 0px 0px 2px currentColor;

  &:hover,
  &:focus {
    box-shadow: none;
    box-shadow: inset 0px 0px 0px 2px currentColor;
  }
}

// Icon btn
.btn-icon [class^='icon-'],
.btn-icon [class*=' icon-'] {
  font-size: 1.75rem;
  line-height: 1px;
  position: relative;
  top: 0.375rem;
  margin-right: -8px;
}

// Fill effect
.fill-hover {
  position: relative;

  span {
    z-index: 4;
    position: relative;
  }
  &:after {
    @extend .hover-open;
    z-index: 2;
    border-radius: 50px;
  }

  &.btn-primary {
    &:hover,
    &:focus {
      background: $primary;
    }
    &:after {
      background-color: darken($primary, 8%);
    }
  }

  &.btn-outline-primary {
    span {
      @extend .ease;
    }

    &:hover {
      background: transparent;
      color: darken($primary, 8%);

      span {
        color: $white;
      }
    }

    &:after {
      background-color: $primary;
    }
  }
}

.stickScroll {
  z-index: 10;
}

.text-secondary-primary,
a.text-secondary-primary {
  color: $primary;
}

// Hover with color schema
.btn.rounded-0:not(.btn-primary) {
  box-shadow: 0 200px 0 0 rgba(0, 0, 0, 0) inset;

  &:hover {
    box-shadow: 0 200px 0 0 rgba(0, 0, 0, 0.2) inset !important;
  }
}

.not-btn {
  cursor: default !important;
}
