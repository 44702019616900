@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?bwnlwp');
  src:  url('../fonts/icomoon.eot?bwnlwp#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?bwnlwp') format('truetype'),
    url('../fonts/icomoon.woff?bwnlwp') format('woff'),
    url('../fonts/icomoon.svg?bwnlwp#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e924";
}
.icon-angle-down:before {
  content: "\e900";
}
.icon-angle-left:before {
  content: "\e901";
}
.icon-angle-right:before {
  content: "\e902";
}
.icon-angle-up:before {
  content: "\e903";
}
.icon-arrow-down:before {
  content: "\e904";
}
.icon-arrow-left:before {
  content: "\e905";
}
.icon-arrow-right:before {
  content: "\e906";
}
.icon-arrow-up:before {
  content: "\e907";
}
.icon-download:before {
  content: "\e908";
}
.icon-facebook:before {
  content: "\e909";
}
.icon-instagram:before {
  content: "\e90a";
}
.icon-linkedin:before {
  content: "\e90b";
}
.icon-phone:before {
  content: "\e90c";
}
.icon-search:before {
  content: "\e90d";
}
.icon-tag1:before {
  content: "\e90e";
}
.icon-tag2:before {
  content: "\e90f";
}
.icon-tag3:before {
  content: "\e910";
}
.icon-tag4:before {
  content: "\e911";
}
.icon-tag5:before {
  content: "\e912";
}
.icon-tag6:before {
  content: "\e913";
}
.icon-tag7:before {
  content: "\e914";
}
.icon-tag8:before {
  content: "\e915";
}
.icon-tag9:before {
  content: "\e916";
}
.icon-tag10:before {
  content: "\e917";
}
.icon-tag11:before {
  content: "\e918";
}
.icon-tag12:before {
  content: "\e919";
}
.icon-tag13:before {
  content: "\e91a";
}
.icon-tag14:before {
  content: "\e91b";
}
.icon-tag15:before {
  content: "\e91c";
}
.icon-tag16:before {
  content: "\e91d";
}
.icon-tag17:before {
  content: "\e91e";
}
.icon-tag18:before {
  content: "\e91f";
}
.icon-tag19:before {
  content: "\e920";
}
.icon-tag20:before {
  content: "\e921";
}
.icon-tag21:before {
  content: "\e922";
}
.icon-youtube:before {
  content: "\e923";
}
