// .blog-latest {

.view-more {
  padding-top: 4.375rem; // 70px
  padding-bottom: 6.25rem; // 100px

  @include media-breakpoint-down(xs) {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
}
// }

// Icon big
.big-icon {
  border: 5px solid;
  display: inline-block;
  width: 190px;
  height: 190px;
  position: relative;
  line-height: 90px;

  @include media-breakpoint-down(md) {
    width: 120px;
    height: 120px;
  }

  .icon {
    font-size: 92px;
    top: 50%;
    display: inline-block;

    @include media-breakpoint-down(md) {
      font-size: 54px;
    }
  }
}
