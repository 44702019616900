// Search form

.form-control {
  border-top: none;
  border-right: none;
  border-left: none;
  padding: 10px 10px;

  &.form-line {
    background: transparent;
    padding: 10px 0px;
    border-color: $gray-500;
    color: $white;
    border-width: 2px;

    &:focus {
      border-color: $gray-300;
    }

    &.textarea {
      margin-top: 20px;
      border-top: 2px solid $gray-500;
      border-right: 2px solid $gray-500;
      border-left: 2px solid $gray-500;
      padding-left: 10px;
      padding-right: 10px;

      &::placeholder {
        opacity: 1;
        transition: all 0.2s;
        color: $gray-500;
      }

      &:focus {
        border-color: $gray-200;
      }
    }
  }
}

.form-control-lg {
  padding: 15px 15px;
  font-size: 16px;

  @include media-breakpoint-down(xs) {
    padding: 10px 10px;
  }
}

// Search bar
.search-bar-wrapper {
  margin-bottom: 6.875rem; // 110px
  vertical-align: top;
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.search-bar {
  form {
    max-width: 700px;
  }

  .btn {
    height: 55px;
  }

  .form-group {
    width: calc(100% - 61px);
    max-width: 700px;
    margin-bottom: 0;

    @include media-breakpoint-down(xs) {
      width: calc(100% - 51px);
    }
  }

  .form-control {
    padding-right: 70px;
    height: 55px;
  }

  .btn.disabled,
  .btn:disabled {
    opacity: 1;
  }

  .loader {
    margin: -2px 0 0px -1px;
    display: none;
  }

  &.loading {
    .loader {
      display: block;
    }
    .icon-search {
      display: none;
    }
  }

  .icon-close {
    font-size: 30px;
    position: absolute;
    display: block;
    width: 60px;
    height: 54px;
    bottom: 1px;
    right: 75px;
    text-align: center;
    line-height: 1.8;
    border-left: 1px solid $border-color;
    cursor: pointer;
    display: none;

    &:hover {
      color: $primary;
    }

    @include media-breakpoint-down(xs) {
      font-size: 24px;
      width: 50px;
      height: 43px;
      right: 65px;
      line-height: 1.5;
    }
  }

  &.loaded .icon-close {
    display: block;
  }

  .btn {
    width: 60px;
    height: 55px;
    padding: 0 0;
    font-size: $h4-font-size;

    @include media-breakpoint-down(xs) {
      height: 45px;
      width: 50px;
    }
  }

  .icon-angle-right {
    font-size: 38px;
    top: -2px;
    left: 1px;
    position: relative;
    display: none;
  }

  &.loaded {
    .icon-angle-right {
      display: block;
    }
    .icon-search {
      display: none;
    }
  }

  .icon-close {
    font-size: 30px;
  }
}

ul.search-results {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.16);
  display: block;
  margin: 0;
  position: absolute;
  background: $white;
  width: calc(100% - 30px);
  padding: 0px 15px;
  z-index: 5;
  overflow: auto;
  max-height: 190px;
  transition: max-height 0.25s ease-in;

  li {
    font-size: 14px;
    padding: 10px 0;
    line-height: 1.4;

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }

    a {
      color: $body-color;

      &:hover {
        color: $primary;
      }
    }

    .name {
      @include font-family('LatoBold', bold, normal);
    }
    .int {
      @include font-family('Lato', normal, normal);
      font-style: italic;
      color: $gray-500;
    }
    .highlight {
      color: $primary;
    }
  }
}

// FLOAT LABEL

.has-float-label {
  display: block;
  position: relative;

  label,
  > span {
    position: absolute;
    left: 0;
    top: -20px;
    cursor: text;
    font-size: 12px;
    opacity: 1;
    transition: all 0.2s;
    content: "";
    margin-bottom: 0;
  }

  .form-line ~ label,
  > .form-line ~ span {
    top: -6px;
  }

  input,
  select {
    &::placeholder {
      opacity: 1;
      transition: all 0.2s;
      color: $gray-500;
    }
    &:placeholder-shown:not(:focus)::placeholder {
      opacity: 0;
    }
    &:placeholder-shown:not(:focus) + * {
      opacity: 1;
      content: "";
      top: 10px;
      font-size: 1rem;
      margin-bottom: 0;
      left: 10px;
    }
    &.form-line:placeholder-shown:not(:focus) + * {
      left: 0px;
    }
    &:focus {
      outline: none;
      border-color: $gray-200;
    }
  }
}

// Custom check
.custom-control-label:before {
  border-radius: 3px;
}
.custom-control-description {
  line-height: 2;
}

// Change Autocomplete styles in Chrome

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $white;
  transition: background-color 5000s ease-in-out 0s;
}
