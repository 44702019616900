// Tabs convenios
.tabs-convenios {
  .nav-tabs li a:not(.active) {
    opacity: 0.5;
  }
}

.tab-content-convenios {
  p:last-of-type {
    margin-bottom: 0;
  }
}

.tabs-mapas {
  .ver {
    @extend .abs-center-x;
  }

  .nav-link {
    position: relative;

    &.active,
    &:hover {
      color: $body-color;
      .ver,
      .icon {
        color: $primary;
      }

      &:after {
        content: " ";
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: auto;
        top: auto;
        bottom: -26px;
        border: 22px solid;
        border-color: transparent transparent $gray-100 transparent;
        margin-left: -22px;

        @include media-breakpoint-down(md) {
          border-color: transparent transparent transparent transparent;
        }
      }
    }
  }
}
