.main-menu {
  li {
    color: $gray-800;
    position: relative;

    .description {
      display: none;
    }

    &.global-search {
      margin-top: 10px;
    }

    &.current-menu-item a {
      color: $primary;
    }

    &.dropdown {
      padding-right: 20px;
    }

    &.active a {
      &:before {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
      }
    }

    a {
      text-transform: uppercase;
      font-size: 13px;
      color: $gray-800;
      font-weight: bold;
      margin-left: 14px;
      margin-right: 14px;
      letter-spacing: 0.1px;
      position: relative;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: block;
      padding: 4px 0px;
      margin-top: 14px;
      cursor: pointer;

      &:hover,
      &:focus,
      &.show {
        outline: none;
        color: $primary;
      }

      &.dropdown-toggle {
        &:after {
          @extend .icon-font;
          border: none;
          content: '\e900';
          font-size: 20px;
          top: 2px;
          right: -20px;
          position: absolute;
        }
      }

      &[aria-expanded='true'] {
        &:after {
          content: '\e903';
        }
      }
    }

    & > a:before {
      @extend .hover-open;
      width: calc(100% - 1px);
      height: 2px;
      bottom: -1px;
      background: $primary;
      z-index: 1;
    }
  }

  // Submenu

  & .dropdown-menu {
    background: $gray-100;
    left: 50%;
    padding: 20px 10px 20px 0;
    -webkit-transform: translate(-50%, 20px);
    transform: translate(-50%, 20px);
    margin: 0 0;
    top: 28px;

    &.show {
      top: 35px;
    }

    .compact &.show {
      top: 8px;
    }

    .description {
      display: inline-block;
    }

    a.dropdown-item {
      @extend .prelo-semibold;
      text-transform: initial;
      font-size: 14px;
      margin: 0 0;
      max-width: 33.333%;
      padding: 10px 30px 10px 30px;
      min-width: 200px;
      border-right: 1px solid darken($gray-100, 10%);

      &:last-child() {
        border-right: none;
      }

      span.name {
        position: relative;
        line-height: 1.2;
        margin-bottom: 10px;
        display: inline-block;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: -6px;
          left: 0;
          background: darken($gray-100, 10%);
          z-index: 1;
        }

        &:before {
          @extend .hover-open;
          height: 2px;
          bottom: -6px;
          background: currentColor;
          z-index: 2;
        }
      }

      span.description {
        @extend .lato;
        display: block;
        font-size: 11px;
        // break text
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &:hover,
      &:focus,
      &.active {
        color: $primary;
        background: transparent;

        span.name:before {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
          opacity: 1;
        }
      }
    }
  }
}

// Fade in dropdown
.dropdown-menu {
  @extend .ease;
  opacity: 0;
  pointer-events: none;
  display: block !important;

  &.show {
    opacity: 1;
    pointer-events: inherit;
  }
}

// Menu institución

.menu-institucion {
  height: 61px;
  background: $primary;

  .collapse-wrapper {
    @include media-breakpoint-down(xs) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  .navbar-collapse {
    @include media-breakpoint-up(sm) {
      height: 100%;
    }
  }
}

.secondary-menu {
  ul.menu {
    @extend .d-sm-flex;
    @extend .justify-content-md-end;
    @extend .align-items-center;
    @extend .mb-0;
    @extend .h-100;
    @extend .list-unstyled;
    position: relative;
    @include media-breakpoint-down(md) {
      margin-left: 180px;
    }
    @include media-breakpoint-down(sm) {
      margin-left: 100px;
    }
    @include media-breakpoint-down(xs) {
      margin-left: 0 !important;
    }
    &:before {
      @extend .lato-bold;
      content: '';
      font-size: 24px;
      height: 40px;
      // width: 150px;
      width: 300px;
      position: absolute;
      top: 13px;
      left: 0;
      text-transform: uppercase;
      white-space: nowrap;
      text-align: right;
      // transform: translateX(-170px);
      transform: translateX(-320px);
      @include media-breakpoint-down(sm) {
        font-size: 18px;
        top: 17px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 18px;
        top: 17px;
        display: none;
      }
    }
    @include media-breakpoint-down(xs) {
      background-color: $primary;
    }
    li {
      margin-left: 40px;
      @include media-breakpoint-down(lg) {
        margin-left: 25px;
      }
      @include media-breakpoint-down(md) {
        margin-left: 20px;
        font-size: 13px;
      }
      @include media-breakpoint-down(xs) {
        margin-left: 0px;
        font-size: 18px;
      }
      &:first-child {
        position: relative;
        &:after {
          content: '';
          width: 2px;
          height: 30px;
          position: absolute;
          top: -3px;
          left: -30px;
          background: $white;
          @include media-breakpoint-down(md) {
            left: -22px;
            top: -2px;
            height: 25px;
          }
        }
      }
      a {
        @extend .lato-bold;
        text-transform: uppercase;
        color: $white;
        position: relative;
        @include media-breakpoint-down(xs) {
          padding: 16px 15px 16px 15px;
          display: block;
        }
        &:before {
          @extend .hover-open;
          width: calc(100% - 1px);
          height: 2px;
          bottom: -3px;
          z-index: 1;
          @include media-breakpoint-down(xs) {
            display: none;
          }
        }
      }
      &.current-menu-item {
        & a:before {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
    }

    // Secondary mobile
    &.secondary-mobile {
      margin-left: 110px;
      background-color: transparent !important;
      &:before {
        left: 0;
        transform: translateX(-110px);
        text-align: left;
      }
      li {
        border: none !important;
        a {
          padding: 0px 15px 0px 15px;
        }
      }
    }
  }

  // Desplegable mobile
  &#nav-content {
    @include media-breakpoint-down(xs) {
      a {
        box-shadow: 0 80px 0 0 rgba(0, 0, 0, 0) inset;
        color: $white;

        &:after {
          content: '';
          background: rgba($white, 0.25);
          position: absolute;
          width: 100%;
          height: 1px;
          top: 0px;
          left: 0;
        }
        &:hover {
          box-shadow: 0 80px 0 0 rgba(0, 0, 0, 0.25) inset;
        }
      }
    }
  }
}

// Institución toggler
.toggler {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  &[aria-expanded='true'] .icon-angle-down:before {
    content: '\e903';
  }
}

.global-search {
  li a {
    margin-top: 9px;
    padding: 5px 0px;
    display: block;

    .icon-search {
      font-size: 14px;
      outline: none;

      &:hover {
        color: $primary;
      }
    }
  }

  &_mobile {
    display: block;

    .icon-search {
      font-size: 24px;
      color: $white;
    }
  }
}

// Currrent item menu diplomados
.diplomados-template-default {
  .secondary-menu ul {
    li.item-diplomados {
      a:before {
        -webkit-transform: scaleX(1);
        -o-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }
}

// Current item menu cursos
.cursos-template-default {
  .secondary-menu ul {
    li.item-cursos {
      a:before {
        -webkit-transform: scaleX(1);
        -o-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }
}

// Current item menu detalle empresas
.page-template-template-empresas-medida-detalle {
  .secondary-menu ul {
    li.item-empresas {
      a {
        color: $primary;
        &:before {
          color: $primary;
          -webkit-transform: scaleX(1);
          -o-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
    }
  }
}
