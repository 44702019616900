.table {
  @extend .prelo-medium;
  @extend .table-responsive;
  display: block;
  overflow: auto;
  font-size: 13px;
  margin-bottom: 0;

  &::-webkit-scrollbar-track {
    background-color: transparent;
    margin-top: 5px;
    margin-left: 5px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-400;
  }

  @include media-breakpoint-up(sm) {
    display: table;
  }

  td,
  th {
    border-left: $table-border-width solid $table-border-color;
    line-height: 1.2;
  }
  thead th {
    @extend .prelo-semibold;
    border-bottom: 0;
    color: $white;
    vertical-align: middle;
    text-align: center;
    background: $primary;
  }
  tbody td {
    background-color: darken($gray-300, 5%);
    vertical-align: middle;
    text-align: center;

    &.title {
      background-color: darken($gray-300, 15%);
      color: $white;
    }

    &:first-child {
      text-align: left;
    }
  }

  tr.dark {
    th,
    td {
      background: darken($gray-400, 10%);
      color: $white;
    }
  }

  &-inverted {
    thead th {
      background-color: transparent !important;
      padding: 0 0;
      color: darken($gray-400, 10%);
      text-transform: uppercase;
    }
  }

  // vertical columns
  tbody tr {
    td.vertical-column {
      max-width: 30px;
      position: relative;
      padding: 0 0;

      span {
        display: none;
      }
    }

    &:first-child {
      span {
        transform: rotate(-90deg);
        display: block !important;
        width: 240px;
        height: 20px;
        position: absolute;
        z-index: 3;
        white-space: nowrap;
        left: 19px;
        font-size: 12px;
        letter-spacing: 0.5pt;
        margin-left: -120px;
        top: 80%;
        margin-top: 50%;
        text-align: center;
      }
    }
  }
}

.tab-bottom {
  background-color: $gray-400;
  width: calc(100% - 81px);
  margin-left: 43px;
}

// Rewrite
.table-primary {
  background: $primary;
}

.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #fff !important;
}

// Table single cursos
.table-cursos {
  thead th {
    background-color: #0076ba;
  }

  &.table-inverted {
    tr:last-child td {
      background: lighten(#0076ba, 52%);
    }
  }

  .btn-cursos {
    background: #0376b9;
    color: $white;

    &:hover,
    &.focus,
    &:focus {
      color: $white;
      background-color: darken(#0376b9, 7%);
    }
  }
}

// Table single cursos
.table-cursos {
  thead th {
    background-color: #0076ba;
  }

  &.table-inverted {
    tr:last-child td {
      background: lighten(#0076ba, 52%);
    }
  }

  .btn-cursos {
    background: #0376b9;
    color: $white;

    &:hover,
    &.focus,
    &:focus {
      color: $white;
      background-color: darken(#0376b9, 7%);
    }
  }
}
