// INSTITUCIONES
// > ARANCELES

.back-init {
  padding-left: 45px;
  line-height: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  color: $gray-800;

  @include media-breakpoint-down(xs) {
    padding-top: 7px;
    padding-bottom: 5px;
    padding-left: 25px;
  }

  br {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  .icon-circle {
    position: absolute;
    left: 0;
    top: 4px;
  }
}

.icon-circle {
  @extend .ease;
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50%;
  font-size: 28px;
  text-align: center;
  padding-top: 6px;
  background: $gray-800;

  @include media-breakpoint-down(xs) {
    width: 20px;
    height: 20px;
    font-size: 16px;
    padding-top: 2px;
  }
}

.back-init-wrapper {
  @include media-breakpoint-down(sm) {
    border-color: transparent !important;
  }
}

// Default color
.content-primary {
  .back-init:hover {
    color: $primary;

    .icon-circle {
      background: $primary;
    }
  }
}
