.section-title {
  margin-bottom: 2.5rem; // 40px
  padding-bottom: 1.875rem; // 30px
  position: relative;
  max-width: 860px;

  strong {
    display: block;
  }

  small {
    font-size: $h5-font-size;
    top: 0px;
    position: relative;
    display: inline-block;

    @include media-breakpoint-down(sm) {
      top: 5px;
    }
  }

  .title {
    line-height: 1.4;

    @include media-breakpoint-down(xs) {
      line-height: 1.1;
      font-size: 18px;
    }
  }
}

.sepline {
  position: relative;

  &:after {
    content: "";
    width: 28px;
    height: 4px;
    background: currentColor;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &_center {
    &:after {
      @extend .abs-center-x;
      left: auto;
    }
  }
}
