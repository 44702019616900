html,
body {
  font-size: 16px;
  height: 100%;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  // display: flex; // css para ordenar los divs del template
  // flex-direction: column; // css para ordenar los divs del template

  @include media-breakpoint-down(xs) {
    font-size: 14px;
  }
}

* {
  -webkit-backface-visibility: hidden;
}

a,
input[type='submit'],
button,
.btn,
label,
input[type='text'],
input[type='password'],
select,
textarea,
.form-control {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

a {
  &:focus {
    outline: none;
  }

  // reset ease in mobile
  .mobile & {
    transition: all 0s ease-in-out;
  }
}

button {
  @extend .prelo-book;
}

input,
optgroup,
select,
textarea {
  @extend .lato;
}

textarea {
  resize: none;
}

b,
strong {
  @extend .prelo-semibold;
}

// REWRITE BOOTSTRAP
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  // -webkit-appearance: none;
}

@media (max-width: map-get($grid-breakpoints, lg)) {
  .container {
    max-width: 100%;
  }
}

// Animate titles
.jsTriggerTitle,
.jsTriggerTitle1,
.jsTriggerTitle2,
.jsTriggerTitle3,
.jsTriggerTitle4,
.jsTriggerTitle5,
.invisible {
  opacity: 0;
}
