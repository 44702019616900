@mixin font-face($style-name, $file, $weight, $style) {
  $filepath: '../fonts/' + $file;
  @font-face {
    font-family: '#{$style-name}';
    src: url($filepath+'.eot');
    src: url($filepath+'.eot?#iefix') format('embedded-opentype'),
      url($filepath+'.woff2') format('wof2f'),
      url($filepath+'.woff') format('woff'),
      url($filepath+'.ttf') format('truetype'),
      url($filepath+'.svg#'+$style-name+'') format('svg');
    font-weight: $weight;
    font-style: $style;
    font-display: block;
  }
}

@mixin font-family($style-name, $weight, $style) {
  font-family: $style-name, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif !important;
  font-weight: $weight !important;
  font-style: $style;
}
