.breadcrumb {
  height: 22px;
  font-size: 16px;

  .separator {
    line-height: 0.6;
    font-size: 1rem;
  }

  #breadcrumbs {
    padding-left: 195px;
    vertical-align: top;
    line-height: 1.1;

    li {
      vertical-align: middle;
    }

    @include media-breakpoint-down(md) {
      padding-left: 0px;
    }
  }

  &.compact #breadcrumbs {
    padding-left: 0;
  }
}
