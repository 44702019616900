.accordion {
  .card-header,
  .content,
  .cart-title {
    margin-bottom: 2px;
  }

  .cart-title {
    background-color: #dbdbdb;
    letter-spacing: 0.5pt;
    position: relative;
    margin-top: 10px;

    &:after {
      background-color: currentColor;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 100%;
    }
  }

  .link,
  .content {
    background-color: #e9e9e9;
    position: relative;
  }
  .content {
    font-size: 14px;
  }

  .link {
    a {
      color: $gray-800;
      &:before {
        font-family: 'icomoon';
        content: '\e900';
        font-size: 24px;
        width: 25px;
        height: 25px;
        position: absolute;
        top: 10px;
        right: 10px;
        color: $primary;
      }
    }

    &:hover a,
    [aria-expanded='true'] {
      color: $white;

      &:before {
        color: $white;
      }
    }

    [aria-expanded='true'] {
      &:before {
        content: '\e903';
      }
    }

    &-primary {
      a {
        color: $primary;
      }
      &:hover a,
      [aria-expanded='true'] {
        background-color: $primary;
      }
    }
  }
}
