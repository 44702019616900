.content-column {
  .background-cover {
    @include media-breakpoint-down(sm) {
      width: calc(100% + 30px);
      height: 260px;
      margin: 0px -15px -70px;
    }
  }
  &.bg-light {
    @include media-breakpoint-down(sm) {
      background: $white !important;
    }
  }
  .column-text {
    @include media-breakpoint-down(sm) {
      background: $gray-300;
    }
  }

  .sepline:after {
    @include media-breakpoint-down(sm) {
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
}

.column-text {
  .title {
    strong {
      @extend .prelo-semibold;
      @extend .d-block;
      @extend .mt-1;
      @extend .mb-4;
      @extend .pb-5;
      @extend .mb-sm-6;
      @extend .pb-sm-6;
      @extend .d-inline-block;
    }
  }
}

.img-carousel {
  height: 100%;
  opacity: 0;

  * {
    height: 100%;
  }
}
