.listado-instituciones {
  padding-left: 0px;
  padding-right: 0px;

  & ~ .section-title {
    margin-top: 55px;
  }
}

// Card
.card {
  &__institucion {
    position: relative;
    height: 170px;

    @include media-breakpoint-down(xs) {
      height: auto;
    }

    .card-title,
    .card-text {
      @include media-breakpoint-down(xs) {
        font-size: $h5-font-size;
      }
    }

    .image-wrapper {
      min-height: 35px;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: -1px;
    }

    .icon-angle-right {
      right: 5px;
      font-size: 1.4rem;

      @include media-breakpoint-up(sm) {
        color: $gray-500;
      }

      @include media-breakpoint-down(xs) {
        font-size: 2.6rem;
      }
    }

    a {
      color: currentColor;
      height: 100%;

      &:after {
        @extend .hover-open;
        height: 7px !important;

        // @include media-breakpoint-down(xs) {
        //   transform: scaleX(1);
        // }
      }
      &:after:focus {
        @extend .hover-open;
        height: 10px !important;

        @include media-breakpoint-down(xs) {
          transform: scaleX(1);
        }
      }

      img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.6;

        @include media-breakpoint-down(xs) {
          -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
          opacity: 1;
        }
      }

      &:hover {
        .icon-angle-right {
          color: currentColor;
        }

        img {
          -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
          opacity: 1;
        }
      }
    }
  }

  // Blog latest
  &__blog {
    position: relative;
    padding-bottom: 35px;

    a {
      color: $gray-600;
    }

    .card-image {
      margin: -1px;
    }

    .btn {
      color: $gray-400;
      font-size: 14px;
      position: relative;
      border: 0;
      z-index: 4;
      bottom: 0;
      position: absolute;
      width: 100%;

      &:after {
        @extend .hover-open;
        background: $gray-500 !important;
      }
    }

    .icon {
      font-size: 20px;
      line-height: 1px;
      top: 3px;
      position: relative;
    }

    &:hover {
      background: $gray-300;

      .btn {
        color: $white;
      }
      .btn:after {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }
}

.image-wrapper-icon {
  flex-basis: 70px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
}
