// HAMBURGER

.hamburger {
  padding: 0;
  margin: 0 5px 0px 40px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font-family: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  overflow: visible;
  padding: 0;
  right: -5px;
  position: relative;
  display: block;
  width: 30px;
  height: 25px;

}

.hamburger-box {
  width: 30px;
  height: auto;
  display: inline-block;
}

.hamburger-inner {
  display: block;
  top: 50%;
  width: 100%;
  height: 3px;
  background-color: $white;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;

  &::before,
  &::after {
    width: 100%;
    height: 3px;
    background-color: $white;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }
  &::before {
    top: -10px;
  }
  &::after {
    bottom: -11px;
  }
}

// Collapse

.hamburger--collapse {
  .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::after {
      top: -20px;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        opacity 0.1s linear;
    }

    &::before {
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
  &.is-active .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    &::after {
      top: 0;
      opacity: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        opacity 0.1s 0.22s linear;
    }

    &::before {
      top: 0;
      transform: rotate(-90deg);
      transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}

// MMENU
.mm-hidden {
  display: none !important;
}

.mm-wrapper {
  overflow-x: hidden;
  position: relative;
}

.mm-menu {
  @extend .lato-bold;
  box-sizing: border-box;
  background: inherit;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 75px;
  bottom: 0;
  z-index: 0;

  &.compact {
    top: 135px;
  }

  @include media-breakpoint-up(lg) {
    display: none !important;
  }

  a {
    color: inherit;
    text-decoration: none;
    &:active,
    &:hover,
    &:link,
    &:visited {
      color: inherit;
      text-decoration: none;
    }
  }
}

.mm-panels {
  background: inherit;
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;

  > .mm-panel {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;

    &#menu-menu {
      padding-top: 0;

      a {
        text-transform: uppercase;
      }
    }
  }
}

.mm-panel:not(:first-of-type) {
  .mobile-menu .nav-item a {
    @extend .prelo-semibold;
    font-size: 15px;
    color: $gray-500;
    line-height: 1.6;
    padding: 16px 8px 16px 18px;

    .description {
      @extend .prelo-medium;
      font-size: 11px;
    }
  }
}

.mm-panel {
  background: inherit;
  box-sizing: border-box;
  margin: 0;
}

.mm-panel {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  &:not(.mm-hidden) {
    display: block;
  }
}

.mm-hasnavbar {
  padding-top: 60px;
}

.mm-opened {
  z-index: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mobile-menu .nav-item_opened > .mm-panel {
  display: block;
}

.mobile-menu .nav-item_opened > .mm-next:after {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  right: 19px;
}

.mm-btn {
  box-sizing: border-box;
  width: 40px;
  height: 60px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.mm_fullwidth {
  width: 100%;
}

.mm-next:after {
  content: '';
  border-top: 2px solid $body-color;
  border-left: 2px solid $body-color;
  box-sizing: content-box;
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
}

.mm-prev:before {
  content: '';
  border-top: 2px solid $primary;
  border-left: 2px solid $primary;
  box-sizing: content-box;
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 20px;
  right: auto;
}

.mm-next:after {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 17px;
  left: auto;
}

.mm-navbar {
  text-align: center;
  line-height: 20px;
  display: none;
  padding: 0 40px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transition: opacity 0.4s ease;
  border-bottom: 1px solid $border-color;

  > * {
    box-sizing: border-box;
    display: block;
    padding: 20px 0;
  }
  a,
  a:link {
    text-decoration: none;
    color: $primary;
    text-transform: uppercase;
    &:hover {
      text-decoration: none;
    }
  }
}

.mm-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  tab-size: left;
  text-align: left;
}

.mm-navbar .mm-btn {
  &:first-child {
    left: 0;
  }
  &:last-child {
    text-align: right;
    right: 0;
  }
}

.mm-hasnavbar .mm-navbar {
  display: block;
}

#menu-menu .mm-navbar {
  display: none;
}

.mobile-menu .nav-item,
.mm-listview {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.mm-listview {
  line-height: 20px;
}

.mobile-menu .nav-item {
  position: relative;
  border-bottom: 1px solid darken($border-color, 3%);

  & a:not(.mm-next):hover,
  & a:not(.mm-next):focus {
    background: $primary;
    color: $white;
  }

  & a.mm-next:hover,
  & a.mm-next:focus {
    & + .menu-item {
      background: $primary;
      color: $white;
    }
    &:after,
    &:before {
      border-color: $white;
    }
  }

  &:last-child {
    border: none;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  > {
    a,
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: inherit;
      display: block;
      padding: 20px 8px 20px 18px;
      margin: 0;
    }
  }
  .mm-next {
    background: rgba(3, 2, 1, 0);
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
    &:not(.mm_fullwidth) {
      width: 100%;
    }
  }
  .mm_fullwidth {
    &:before {
      border-left: none;
    }
  }
}

.mobile-menu .nav-item_divider {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
  padding: 5px 10px 5px 20px;
  &:after {
    content: ' ';
    display: none;
    position: static;
    display: inline-block;
  }
}

.mm-menu {
  background: $gray-100;
}

.mm-page {
  box-sizing: border-box;
  // position: relative;

  @include media-breakpoint-up(lg) {
    min-height: auto !important;
  }
}

.mm-slideout {
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  // z-index: 1;
}

.mm-opened {
  overflow-x: hidden;
  position: relative;
}

.XA .mm-page {
  background: inherit;
}

.mm-menu_offcanvas {
  display: none;
  position: fixed;
  right: auto;
  z-index: 0;
  &.mm-opened {
    display: block;
    // &.mm-no-csstransforms {
    z-index: 10;
    // }
  }
  width: 80%;
  min-width: 140px;
  max-width: 380px;
}

.mm-opening .mm-menu_offcanvas.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(80vw, 0);
  -ms-transform: translate(80vw, 0);
  transform: translate(80vw, 0);
  -webkit-transform: translate3d(80vw, 0, 0);
  transform: translate3d(80vw, 0, 0);
}

@media all and (max-width: 175px) {
  .mm-opening .mm-menu_offcanvas.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  .mm-opening .mm-menu_offcanvas.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    transform: translate(440px, 0);
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0);
  }
}

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;

  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}

.mm-blocking {
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    overflow: auto !important;
  }

  body {
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      overflow: auto !important;
    }
  }
  #mm-blocker {
    display: block;
  }
}

.mm-sronly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  min-width: 1px !important;
  height: 1px !important;
  min-height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
}

[class*='mm-pagedim'].mm-opened ~ #mm-blocker {
  opacity: 0;
}

.mm-opening [class*='mm-pagedim'].mm-opened ~ #mm-blocker {
  opacity: 0.75;
  transition: opacity 0.4s ease 0.4s;
}

.mm-opened {
  &.mm-pagedim-black ~ #mm-blocker {
    background: #000;
  }
}

.mm-position-right {
  left: auto;
  right: 0;
}

.mm-opening .mm-position-right.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(-80vw, 0);
  -ms-transform: translate(-80vw, 0);
  transform: translate(-80vw, 0);
  -webkit-transform: translate3d(-80vw, 0, 0);
  transform: translate3d(-80vw, 0, 0);
}

@media all and (max-width: 175px) {
  .mm-opening .mm-position-right.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  .mm-opening .mm-position-right.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(-440px, 0);
    -ms-transform: translate(-440px, 0);
    transform: translate(-440px, 0);
    -webkit-transform: translate3d(-440px, 0, 0);
    transform: translate3d(-440px, 0, 0);
  }
}

// .mm-menu_position-bottom,
.mm-position-front {
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.mm-position-front.mm-opened {
  z-index: 8;
}

.mm-position-front.mm-opened ~ .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0;
}

.mm-position-front.mm-opened ~ #mm-blocker {
  z-index: 7;
}

.mm-position-front {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  &.mm-position-right {
    -webkit-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    transform: translate(100%, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.mm-opening {
  .mm-menu_position-bottom,
  .mm-position-front,
  .mm-menu_position-top {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
