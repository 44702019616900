// Fonts
// ===========

@import 'common/mixins';
@import 'common/icons';
@import 'common/fonts';

/*
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import '../../node_modules/bootstrap/scss/functions';
@import 'common/variables'; // rewrite bootstrap variables
@import '../../node_modules/bootstrap/scss/variables'; // disable bootstrap variables
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/print';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/code';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/dropdown';
// @import "../../node_modules/bootstrap/scss/button-group";
// @import "../../node_modules/bootstrap/scss/input-group";
@import '../../node_modules/bootstrap/scss/custom-forms';
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/navbar';
// @import "../../node_modules/bootstrap/scss/card";
// @import "../../node_modules/bootstrap/scss/breadcrumb";
// @import "../../node_modules/bootstrap/scss/pagination";
// @import "../../node_modules/bootstrap/scss/badge";
// @import "../../node_modules/bootstrap/scss/jumbotron";
@import '../../node_modules/bootstrap/scss/alert';
// @import "../../node_modules/bootstrap/scss/progress";
// @import "../../node_modules/bootstrap/scss/media";
// @import "../../node_modules/bootstrap/scss/list-group";
// @import "../../node_modules/bootstrap/scss/close";
@import '../../node_modules/bootstrap/scss/modal';
// @import "../../node_modules/bootstrap/scss/tooltip";
// @import "../../node_modules/bootstrap/scss/popover";
// @import "../../node_modules/bootstrap/scss/carousel";
@import '../../node_modules/bootstrap/scss/utilities';

// Slick carousel
// ===========

@import '../../node_modules/slick-carousel-no-font-no-png/slick/slick.scss';
@import '../../node_modules/slick-carousel-no-font-no-png/slick/slick-theme.scss';
// @import "slick-carousel/slick/slick-theme.scss";

// Common
// ===========
@import 'common/global';
@import 'common/helpers';

// Components
// ===========
@import 'components/wp-classes';
@import 'components/buttons';
@import 'components/hero';
@import 'components/forms';
@import 'components/hubspot-forms';
@import 'components/modal';
@import 'components/custom-selects';
@import 'components/card-links';

// Layouts
// ===========
@import 'layouts/header';
@import 'layouts/menu';
@import 'layouts/menu-mobile';
@import 'layouts/footer';
@import 'layouts/breadcrumb';
@import 'layouts/carousel';
@import 'layouts/accordion';
@import 'layouts/accordion-h';

// Layouts partials
// ===========
@import 'layouts/partials/section-title';
@import 'layouts/partials/listado-instituciones';
@import 'layouts/partials/flags';
@import 'layouts/partials/content-column';
@import 'layouts/partials/table';
@import 'layouts/partials/tabs';

// Templates
// ===========
@import 'templates/login';
@import 'templates/home';
@import 'templates/institucion';
@import 'templates/institucion_detalle';
@import 'templates/institucion_aranceles';
@import 'templates/sobre_eclass';
@import 'templates/empresas';

.select-section {
    .dropdown-eclass {
      width: 100%;
      padding: 10px;
      display: inline-block;
      background: #f6f6f6 0% 0% no-repeat padding-box;
      -webkit-box-shadow: 0 0 2px #cccccc;
      box-shadow: 0 0 2px #cccccc;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease;
      position: relative;
      font-size: 13px;
      color: #2f2f2f;
      height: 100%;
      text-align: left;
      cursor: pointer;
      .select {
        display: block;
        padding: 10px;
        > i {
          font-size: 13px;
          color: #888;
          cursor: pointer;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          float: right;
          line-height: 20px;
        }
      }
      &:hover {
        /* -webkit-box-shadow: 0 0 4px #cccccc;
        box-shadow: 0 0 4px #cccccc; */
        background: #dadada;
      }
      &:active {
        background-color: #f8f8f8;
        .select > i {
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }
      &.active:hover,
      &.active {
        -webkit-box-shadow: 0 0 4px #cccccc;
        box-shadow: 0 0 4px #cccccc;
        border-radius: 2px 2px 0 0;
        background-color: #f8f8f8;
      }
    }
    .container .dropdown-menu-eclass {
      position: unset;
      background: transparent;
      border: 0;
      width: 100%;
      left: 0;
      margin-top: 1px;
      overflow: hidden;
      display: none;
      .dropdown-menu-eclass {
        li {
          padding: 10px;
          -webkit-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out;
          cursor: pointer;
          background-color: #116144;
          color: white;
          border: 1px solid #ffffff66;
          font-size: 13px;
          letter-spacing: 0px;
          border-top: 0px;
          height: 99px;
        }
        .item-fig::before {
          content: "";
          width: 0;
          height: 0;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          border-left: 20px solid #116144;
          position: relative;
          top: -58px;
          -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
          left: 150px;
        }
        .item::before {
          content: "";
          width: 20px;
          height: 0;
        }
      }
    }
    .dropdown-menu-eclass li:hover {
      background-color: #9bc03a;
    }
    .dropdown-eclass .dropdown-menu-eclass li:active {
      background-color: #e2e2e2;
    }
    .container-mobile {
      display: none;
    }
  }
  
  @media (max-width: 991.98px) {
    .select-section .container-mobile {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .content-page{
      overflow: unset;
    }
  }
  .select-section .container-desktop {
    display: none;
  }
  @media (min-width: 992px) {
    .select-section .container-desktop {
      display: block;
    }
  }
  .select-section .arrow {
    color: #116144;
  }
  .select-section a {
    color: white;
    text-decoration: none;
  }
  
  .sticky{
    position: -webkit-sticky !important; /* Safari */
    position: sticky !important;
    top: 0;
    z-index: 200;
  }  