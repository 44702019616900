.login {
  @include font-family("PreloBook", normal, normal);

  .input {
    box-shadow: none;

    &:focus {
      box-shadow: none;
      border-color: $gray-700;
    }
  }
  input#user_login {
    @include font-family("PreloBook", normal, normal);
  }
  h1 a {
    background: url("../images/svg/logo.svg") center no-repeat;
    background-size: contain;
    width: 200px;
    height: 85px;
    margin: 0 auto 0;
  }
  & .button {
    @include font-family("PreloMedium", 500, normal);
    background: $primary;
    box-shadow: none;
    border: none;
    text-shadow: none;
    text-transform: uppercase;
    border-radius: 50px;
    padding: 0 25px;

    &:hover,
    &:focus,
    &:active {
      background: darken($primary, 10%);
      outline: none;
      box-shadow: none;
    }
  }
  & #backtoblog a:hover,
  & #nav a:hover,
  & h1 a:hover {
    color: $primary;
  }
  & input[type="checkbox"]:checked:before {
    color: $primary;
    outline: none;
    box-shadow: none;
  }
  & input[type="checkbox"]:checked:before,
  input[type="checkbox"]:focus {
    color: $primary;
    outline: none;
    box-shadow: none;
    border-color: $gray-700;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $body-color;
  transition: background-color 5000s ease-in-out 0s;
}
