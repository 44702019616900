.footer {
  padding-top: 100px;
  padding-bottom: 100px;

  @include media-breakpoint-down(xs) {
    padding-top: 70px;
    padding-bottom: 40px;
  }

  ul {
    li.documentos {
      a {
        font-size: 13px;
      }
    }
  }

  .border {
    border-color: $gray-500 !important;
  }

  .title {
    color: $gray-400;
    font-size: 13px;
    padding-bottom: 4px;
  }

  p {
    font-size: 13px;

    a {
      word-break: break-all;
    }
  }

  .content {
    padding: 50px 70px;
    position: relative;

    @include media-breakpoint-down(xs) {
      padding: 20px 65px;
    }
  }

  .logo-footer {
    z-index: 0;
    padding: 0 20px 20px 0;
    top: -20px;
    left: -2px;

    img {
      width: 114px;
      height: 28px;
    }
  }

  .CourseMain,
  .flag-bottom {
    a:hover {
      opacity: 0.8;
    }
  }
}

.social-bottom {
  li {
    a {
      color: $white;
      background: $gray-600;
      display: inline-block;
      width: 33px;
      height: 33px;
      text-align: center;
      border-radius: 100%;
      line-height: 2;
      font-size: 18px;

      &:hover {
        color: $gray-900;
        background: $gray-400;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    margin-bottom: -35px;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.logos-bottom {
  max-width: 600px;
}

.flag-bottom {
  img {
    width: 24px;
  }
}