// @include font-face('LatoBoldItalic', 'Lato-BoldItalic', 'bold', 'italic');
@include font-face('LatoBold', 'Lato-Bold', 'bold', 'normal');
@include font-face('Lato', 'Lato-Regular', 'normal', 'normal');
// @include font-face('LatoItalic', 'Lato-Regular', 'normal', 'italic');
@include font-face('PreloBoldItalic', 'Prelo-BoldItalic', 'bold', 'italic');
@include font-face('PreloBook', 'Prelo-Book', 'normal', 'normal');
@include font-face('PreloBookItalic', 'Prelo-Book', 'normal', 'italic');
@include font-face('PreloBold', 'Prelo-Bold', 'bold', 'normal');
@include font-face('PreloMedium', 'Prelo-Medium', '500', 'normal');
@include font-face('PreloMediumItalic', 'Prelo-Medium', '500', 'italic');
@include font-face('PreloSemiBold', 'Prelo-SemiBold', '600', 'normal');
// @include font-face('PreloLightItalic', 'Prelo-LightItalic', '300', 'italic');
// @include font-face('PreloLight', 'Prelo-Light', '300', 'normal');

// LATO BOLD ITALIC
// .lato-bold-italic {
//   @include font-family('LatoBoldItalic', bold, italic);
// }

// LATO BOLD
.lato-bold {
  @include font-family('LatoBold', bold, normal);
}

// LATO
.lato {
  @include font-family('Lato', normal, normal);
}

// LATO ITALIC
// .lato-italic {
//   @include font-family('LatoItalic', normal, italic);
// }

// PRELO BOLD ITALIC
.prelo-bold-italic {
  @include font-family('PreloBoldItalic', bold, normal);
}

// PRELO BOOK
.prelo-book {
  @include font-family('PreloBook', normal, normal);
}

// PRELO BOOK ITALIC
.prelo-book-italic {
  @include font-family('PreloBookItalic', normal, italic);
}

// PRELO BOLD
.prelo-bold {
  @include font-family('PreloBold', bold, normal);
}

// PRELO MEDIUM
.prelo-medium {
  @include font-family('PreloMedium', 500, normal);
}

// PRELO MEDIUM ITALIC
.prelo-medium-italic {
  @include font-family('PreloMediumItalic', 500, italic);
}

// PRELO SEMIBOLD
.prelo-semibold {
  @include font-family('PreloSemiBold', 600, normal);
}

// PRELO LIGHT ITALIC
// .prelo-light-italic {
//   @include font-family('PreloLightItalic', 300, italic);
// }

// PRELO LIGHT
// .prelo-light {
//   @include font-family('PreloLight', 300, normal);
// }
