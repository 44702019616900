// stylelint-disable
$white: #fff;
$gray-100: #f6f6f6; // eclass
$gray-200: #efefef;
$gray-300: #ececec; // eclass
$gray-400: #b5b5b5; // eclass
$gray-500: #808080;
$gray-600: #616161; // eclass
$gray-700: #495057; // eclass
$gray-800: #4b4b4b; // eclass
$gray-900: #2f2f2f; // eclass
$black: #000;

$grays: () !default;
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900
  ),
  $grays
);

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #cc0000;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$primary: $red;
$secondary: $gray-600;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $white;
$dark: $gray-800;

$colors: () !default;
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800
  ),
  $colors
);

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $red,
    'light': $gray-100,
    'dark': $gray-800,
    'darker': $gray-900
  ),
  $theme-colors
);
