// Titulo contacto
.form-hubspot {
  @extend .form-hubspot;
  @extend .justify-content-center;
  @extend .d-flex;

  .hs-richtext {
    @extend .section-title;
    @extend .sepline;
    @extend .mb-2;
    @extend .pt-0;
    @extend .pb-3;
    @extend .text-primary;
    margin-left: 15px;

    .modal & {
      color: #fff !important;
      margin-left: 0;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @extend .h4;
      }

      &::after {
        display: none;
      }

      strong {
        display: inline-block;
      }
    }

    p {
      @extend .h5;
      @extend .prelo-book;
      @extend .mb-0;
      @extend .title;
      @extend .text-white;

      strong {
        display: inline-block;
      }
    }
  }

  fieldset {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .hs-form-field {
    @extend .col;
    @extend .mb-2;
    position: relative;

    &.hs-fieldtype-text:focus-within,
    &.hs-fieldtype-text.has-value {
      label {
        top: -6px;
        font-size: 12px;
      }
    }

    label {
      color: gray;
      position: absolute;
      cursor: text;
      opacity: 1;
      transition: all .2s;
      content: "";
      margin-bottom: 0;
      top: 10px;
      font-size: 1rem;
    }

    .input {
      margin-right: 0px !important;
    }

    input,
    select,
    textarea {
      @extend .form-control;
      @extend .form-line;
      width: 100% !important;

      &::placeholder {
        opacity: 0;
      }

      &:focus {
        background: transparent;
        outline: none;
        border-color: $gray-200;
      }

      &.invalid {
        border-color: $danger;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus &:-webkit-autofill,
      &:-webkit-autofill,
      &:-webkit-autofill:hover &:-webkit-autofill:focus,
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: $white;
        transition: background-color 5000s ease-in-out 0s;
      }
    }

    &.hs-fieldtype-textarea {
      label {
        top: 5px;
      }
      .input {
        padding-top: 25px;

        textarea {
          height: 60px;
        }
      }
    }
  }

  .actions {
    text-align: center;
    .hs-button {
      border-radius: 50px;
    }
  }
}

.hs-button {
  @extend .btn;
  @extend .btn-block;
  @extend .btn-primary;
  @extend .w-auto;
  @extend .mx-auto;
  width: auto !important;

  .form-wrapper & {
    @extend .btn-sm;
  }
}

// Errors
ul.hs-error-msgs {
  @extend .list-unstyled;
  margin: 0 0;
  padding: 0 0;
  position: relative;

  li {
    label {
      margin: 0 0 8px 0;
      font-size: 10px !important;
      color: $danger !important;
      //position: absolute !important;
      top: 3px !important;
      line-height: 1;
    }
  }
}

ul.inputs-list {
  @extend .list-unstyled;
  margin: 0 0;
  padding: 0 0;
  position: relative;
}

.hs-form-booleancheckbox-display {
  input {
    width: auto !important;
  }
}

label.hs-form-booleancheckbox-display {
  position: relative !important;
  font-size: 12px !important;
  line-height: 1.2;
  color: #f6f6f6 !important;
}

label.hs-form-booleancheckbox-display {
  display: inline-block;
  margin-bottom: 10px !important;
  top: 0 !important;

  .hs-input {
    width: auto !important;
    display: inline-block;
    margin-right: 10px;
    top: -5px !important;
    float: left;
    height: 15px;
  }
}

.modal-body form {
  max-width: 360px;
}
.submitted-message {
  color: #ffffff !important;
}

// Form in footer

.footer-form .form-hubspot {
  .hs-richtext {
    @extend .sepline_center;
    text-align: center;
    margin: 0;
  }
  .actions {
    .hs-button {
      border-radius: 50px;
      padding: 5px 20px;
    }
  }
}
