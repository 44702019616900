.outline {
  outline: 1px solid #f09;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.lh-12 {
  line-height: 1.2;
}

.background-cover {
  background-size: cover;
}

.background-fixed {
  background-attachment: fixed;
}

.background-center {
  background-position: center;
}

.w-auto {
  width: auto;
}

.ease {
  transition: all 0.2s ease-in-out;

  .mobile & {
    transition: all 0s ease-in-out;
  }
}

.ease-0 {
  transition: all 0s ease-in-out;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.abs-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.abs-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.abs-center {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.icon-font {
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// COLORS

.font-base-color {
  color: $body-color;
}

// BACKGROUND COLORS
.bg-ldark {
  background-color: darken($gray-100, 5%);
}

//
.bg-fill {
  &:after {
    content: "";
    width: 1000px;
    height: 100%;
    background: inherit;
    position: absolute;
    top: 0;
    right: -1000px;
  }
}

// HOVER

.hover-open {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  background: currentColor;

  &:hover {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

// Z INDEX

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 5;
}
