.modal {

  &__centered {
  text-align: center;

      &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
      .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
      }
  }

  &__transparent {

    .modal-dialog {
      max-width: 750px !important;
      width: 100%;
    }
    .modal-content {
      background: transparent;
    }
    .modal-body {
      padding: 0 15px;
    }
  }

  &__search {
    @include media-breakpoint-down(xs) {
      padding-top: 40vw;
    }
    .modal-dialog {
      margin: 0 0;
    }
  }

 .close {
    cursor: pointer;
    color: $gray-500;

    &:hover, &:focus {
      color: $gray-200;
    }
  }
}
