.carousel {
  @include media-breakpoint-down(xs) {
    // if js disabled
    & > div {
      display: none;
      outline: none;

      &:first-of-type {
        display: block;
      }
    }

    // if js enabled
    &.slick-initialized {
      & > div {
        display: block;
      }
    }
  }
}

.column-carousel {
  & > * {
    display: none;
    outline: none;

    &:first-of-type {
      display: block;
    }
  }

  // if js enabled
  &.slick-initialized {
    & > * {
      display: block;
    }
  }
}

// Disable outline focus
.carousel:focus,
.slick-track:focus,
.slick-slide:focus {
  outline: none;
}

// Dots
ul.slick-dots {
  li {
    width: $dot-size;
    height: $dot-size;

    button {
      padding: 0;
      cursor: pointer;
      width: $dot-size;
      height: $dot-size;

      &:before {
        transition: all 0.2s ease-in-out;
        width: $dot-size;
        height: $dot-size;
        background: $primary;
        border-radius: 50%;
      }
    }
  }
}

// Carousel levels
.closeCollapse {
  top: 10px;
  right: 20px;

  &:hover {
    transform: scale(0.8);
  }
}

// Level carousel
.carouselLevels {
  display: none;

  &.slick-initialized {
    display: block;
  }

  .prev {
    left: -80px;
    cursor: pointer;

    &:before {
      color: $primary;
    }

    @include media-breakpoint-down(sm) {
      left: -55px;
    }
  }
  .next {
    right: -80px;
    cursor: pointer;

    &:before {
      color: $primary;
    }

    @include media-breakpoint-down(sm) {
      right: -55px;
    }
  }
  .image-wrapper {
    min-width: 65px;

    img {
      width: auto;
      max-height: 65px;
      margin: 0 auto;
    }
  }
  .section-title strong {
    display: inline-block;
  }
}

// Empresas
.il-carousel {
  .prev,
  .next {
    z-index: 4;
    cursor: pointer;
    width: 50px;
    background: $white;
  }
  .next {
    right: -40px;
  }
  .prev {
    left: -40px;
  }
}
