// Colores
body.template-empresas {
  .menu-primary {
    background: $gray-900 !important;

    li:hover a,
    li.current-menu-item a {
      color: $primary;
      &:before {
        color: $primary;
      }
    }

    & ul.menu.secondary-mobile {
      margin-left: 0;

      &:before {
        display: none;
      }

      li:first-child:after {
        display: none;
      }
    }
  }
  .secondary-menu {
    ul.menu li:first-child:after {
      background: transparent;
    }
  }
}

.list-dots {
  li {
    display: inline-block;
    max-width: 200px;
    margin-top: 20px;
    position: relative;

    @include media-breakpoint-down(lg) {
      max-width: 160px;
    }

    @include media-breakpoint-down(md) {
      max-width: 120px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 90px;
    }

    @include media-breakpoint-down(xs) {
      max-width: 30vw;
    }

    &:not(:last-child) {
      margin-right: 70px;

      &:after {
        content: '';
        background: $primary;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        display: block;
        position: absolute;
        top: 50%;
        right: -40px;

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }

      @include media-breakpoint-down(md) {
        margin-right: 60px;

        &:after {
          right: -35px;
        }
      }

      @include media-breakpoint-down(sm) {
        margin-right: 40px;

        &:after {
          right: -25px;
        }
      }

      @include media-breakpoint-down(xs) {
        margin-left: 15px !important;
        margin-right: 15px !important;
      }
    }

    &:nth-child(4n) {
      margin-right: inherit;

      &:after {
        display: none;
      }

      @include media-breakpoint-down(xs) {
        background: transparent;
        margin-left: inherit;
      }
    }
  }
}

// Planing steps
.planing-steps {
  .items-wrapper {
    .item {
      flex: 1;

      .wrapper-icon {
        img {
          width: 100px;
          height: 100px;
        }
      }

      .content {
        border: 1px dashed $primary;
        max-width: 230px;

        @include media-breakpoint-down(xs) {
          max-width: 200px;
        }

        .number {
          line-height: 1;
        }
      }
    }
  }
}

// Acordeon empresas

.card-empresa {
  margin-bottom: 10px;

  .card-header {
    border-left: 10px solid $primary;

    a:hover,
    a[aria-expanded='true'] {
      background: $gray-900 !important;
    }
  }
}
