.card-link {
  background: $black;
  position: relative;
  cursor: pointer;

  .image {
    opacity: 0.5;
    opacity: 0.9;
    overflow: hidden;

    img {
      width: 100%;
      // height: auto;
      height: 270px;
    }
  }

  .info {
    pointer-events: none;
    @media screen and (max-width: 576px) {
      h5 {
        font-size: 11px;
      }
    }
  }

  .title {
    font-size: 1.25rem;

    strong {
      display: block;
    }
  }

  .info .icon {
    right: 30px;
  }

  &:hover,
  &[aria-expanded='true'],
  &.active {
    background: #000;
    color: $white;

    .image {
      // opacity: 0.2;

      img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }
  // If card if active on toggle
  &.active {
    background: $primary;
    color: $white;

    .info {
      color: $white !important;
    }

    &:hover i {
      color: $white !important;
    }
  }

  //
  &_dropdown[aria-expanded="true"] {
    background: $primary !important;
  }
  &_dropdown {
    &:hover,
    &.active {
      background: $primary;
    }

    .icon {
      transform-origin: right;
      right: 15px;
      font-size: 2.4rem;
      transform: rotate(90deg);
      z-index: 1;
    }

    .number {
      width: 46px;
      height: 46px;
      top: 0;
      margin-top: -25px;
      left: 50%;
      margin-left: -25px;
      line-height: 40px;
      border: 3px solid $primary;
    }

    .title {
      font-size: $h3-font-size;
    }
  }

  //
  &_marker {
    display: block;
    cursor: pointer;

    &[aria-expanded='true'],
    &.active {
      position: relative;

      &:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        z-index: 10;
        border-color: rgba(136, 183, 213, 0);
        // border-top-color: $gray-800;
        border-top-color: #f3f3f3;
        border-width: 30px;
        margin-left: -30px;
      }
    }
  }

  &_vermas {
    max-width: 330px;

    .section-title .title {
      line-height: 1.2;
    }

    .info p {
      font-size: 14px;
      line-height: 1.2;
    }

    .btn {
      font-size: 14px;

      .icon {
        font-size: 20px;
        top: 3px;
      }

      &:after {
        border-radius: 0;
        background-color: $primary;
      }
    }

    &:hover {
      .btn:after {
        transform: scaleX(1);
      }
    }
  }
  &_niveles.card-link_dropdown {
    &[aria-expanded='true'] {
      background: $primary !important;
      &:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        z-index: 10;
        border-color: rgba(136, 183, 213, 0);
        border-top-color: $primary;
        border-width: 14px;
        margin-left: -14px;
      }
    }
  }
  // Tabs
  &_tab {
    .image {
      // opacity: 0.2;
    }

    &[aria-expanded='true'],
    &.active {
      background: $black !important;

      .image {
        // opacity: 0.2;

        img {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }
  }

  &_number {
    &:hover,
    &.active {
      background: #000;

      .number {
        background: $primary !important;
      }
    }
  }
}

.step-tabs {
  .item {
    display: inline-block;

    @include media-breakpoint-down(sm) {
      max-width: 140px;
    }
  }
}

// Steps tabs
.step-tabs-info {
  .carousel {
    .slick-arrow {
      display: none !important;
    }

    .item {
      display: none;

      &:first-child {
        display: block;
      }
    }

    &.slick-initialized .item {
      display: block !important;
    }
  }
}

//
.profesores {
  cursor: pointer;

  .prev {
    left: -80px;
    cursor: pointer;

    &:before {
      color: $primary;
    }

    @include media-breakpoint-down(sm) {
      left: -55px;
    }
  }
  .next {
    right: -80px;
    cursor: pointer;

    &:before {
      color: $primary;
    }

    @include media-breakpoint-down(sm) {
      right: -55px;
    }
  }

  &.card-link_dropdown:hover,
  &.card-link_dropdown.active {
    background: transparent;
    position: relative;

    &:after,
    &:after {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      left: auto;
      right: auto;
      top: auto;
      bottom: -26px;
      border: 15px solid;
      border-color: transparent transparent #e9e9e9 transparent;
      margin-left: -15px;
    }
    img {
      -webkit-filter: grayscale(0);
      filter: grayscale(0);
    }
  }
  img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}

// profesores carousel

.profesoresCarousel {
  &.step-tabs .item {
    max-width: initial;
  }

  .prev {
    left: 0px;
    margin-top: -40px;
    cursor: pointer;
    z-index: 10;
  }
  .next {
    right: 0px;
    margin-top: -40px;
    cursor: pointer;
    z-index: 10;
  }
}

// Split colums
.split-columns {
  @include media-breakpoint-up(md) {
    p {
      text-align: justify;
      font-size: 14px;
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      -webkit-column-gap: 30px;
      -moz-column-gap: 30px;
      column-gap: 30px;
    }
  }
}

// Casos éxito

.casos_exito {
  .card-link {
    border: 1px solid $black;
    background: $black;
    min-height: 150px;
    max-width: 280px;

    &.active,
    &:hover {
      .logo {
        opacity: 0.6;
      }
      .icon-angle-down:before {
        content: '\e903';
      }
    }
  }

  .down {
    height: 20px;
    line-height: 22px;

    &.icon-angle-down:before {
      line-height: 1px;
      height: 15px;
      display: block;
      top: 8px;
      position: relative;
      color: $white;
    }
  }
}

.no-slide {
  transition: none !important;
}
