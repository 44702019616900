.accordion-h {
  width: 100%;
  height: 200px;

  @include media-breakpoint-down(sm) {
    height: auto !important;
  }

  .item {
    width: 100%;
    height: 450px;
    background-position: center;
    background-repeat: no-repeat;

    @include media-breakpoint-down(sm) {
      width: 100%!important;
      left: 0!important;
      position: relative!important;
      margin-bottom: 0 !important;
      height: 250px;
    }

    .cover {
      overflow: hidden;
      transition: all 0.6s ease-in-out;

      @include media-breakpoint-down(sm) {
        width: 100% !important;
      }

      .overlay {
        background: rgba(0, 0, 0, 0.7);
      }

      .background {
        background-position: center;
        transform: scale(1.01);
      }
    }

    .content {
      display: none;

      ul li {
        width: 20%;
        min-width: 140px;

        @include media-breakpoint-down(sm) {
          width: auto;
        }
      }
    }

    &:hover,
    &.active {
      .background {
        transform: scale(1.05);
      }
      .overlay {
        background: rgba(0, 0, 0, 0.6);
      }
    }

    &.active {
      .accordion-close {
        opacity: 1;
        pointer-events: initial;
        display: block;
      }
      .content {
        display: block;

        @include media-breakpoint-down(sm) {
          height: 100%;
          max-height: 270px;
          overflow: auto;
          margin: 15px 0;
          overflow: auto;
          -webkit-overflow-scrolling: touch;

          &::-webkit-scrollbar-track {
            background-color: transparent;
            margin-top: 5px;
            margin-left: 5px;
          }

          &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $gray-400;
          }
        }
      }
    }

    &:hover {
      cursor: pointer;
    }

    &.active .cover {
      width: 100% !important;
    }

    .accordion-close {
      position: absolute;
      top: 10px;
      right: 10px;
      opacity: 0;
      pointer-events: none;
      display: none;

      &:hover {
        opacity: 0.8;
        transform: scale(0.8);
      }
    }
  }
}
