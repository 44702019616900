//  Top bar
.header {
  z-index: 8;
  position: relative;

  .modalSearch:focus {
    outline: none;
  }

  .top {
    height: 35px;

    @include media-breakpoint-down(md) {
      height: 30px;
      padding-top: 2px;
    }

    &.active {
      background: #5e0a0f !important;
    }

    .icon-tag11 {
      font-size: 14px;
      line-height: 1px;
      top: 1px;
      position: relative;
      margin-right: 2px;
    }

    .icon-angle-down {
      font-size: 20px;
      line-height: 1px;
      top: 3px;
      position: relative;

      @include media-breakpoint-down(md) {
        top: 6px;
        right: -5px;
        font-size: 1.75rem;
      }
    }
  }

  .logo {
    padding: 40px 20px 20px;
    margin-bottom: -40px;
    background: $primary;
    top: 0;
    z-index: 6;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      padding: 5px 15px 0 15px;
      position: absolute;
      top: 0;
      left: -15px;
      height: 45px;
      background: transparent;
    }

    img {
      width: 123px;
      height: 31px;

      @include media-breakpoint-down(md) {
        width: 99px;
        height: 32px;
      }
    }
  }

  .inner {
    height: 55px;
    background: $white;

    @include media-breakpoint-down(md) {
      background: $primary;
      height: 45px;
    }
  }

  // Compact header
  @include media-breakpoint-up(lg) {
    &.compact {
      .inner {
        height: 30px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &.compact {
      .dropdown-paises {
        margin: 35px 0 0;
      }

      .navbar-nav {
        padding-top: 2px;
      }

      .main-menu li a {
        margin-top: 0px;
      }

      .global-search li a {
        margin-top: -5px;
      }
    }
  }
}

//  Dropdown paises
.dropdown-paises {
  margin: 35px 0 0;
  right: 0;
  top: 0px !important;
  left: auto !important;
  -webkit-transform: translate(-13px, 20px) !important;
  transform: translate(-13px, 20px) !important;

  @include media-breakpoint-down(md) {
    margin: 28px 0 0;
  }

  .dropdown-item:not(:last-child) {
    border-bottom: 1px solid rgba($gray-500, 0.3);
  }

  &.show {
    -webkit-transform: translate(-13px, 0px) !important;
    transform: translate(-13px, 0px) !important;
  }
}

.select-country[aria-expanded='true'] {
  .icon-angle-down:before {
    content: '\e903';
  }
}
