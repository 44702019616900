// Flags
.flag {
  // background: url('../images/flags.png') 0 0 no-repeat;
  background-size: cover;
  display: inline-block;
  width: 96px;
  height: 72px;
  position: relative;

  &__chile {
    background-position: 0 0;
  }
  &__colombia {
    background-position: 0 25%;
  }
  &__panama {
    background-position: 0 50%;
  }
  &__paraguay {
    background-position: 0 75%;
  }
  &__peru {
    background-position: 0 100%;
  }

  &__small {
    // width: 14px;
    // height: 9px;
    width: 24px;
    height: 24px;
    top: 1px;
  }
  &__medium {
    width: 29px;
    height: 21px;
    top: 1px;
  }
}
