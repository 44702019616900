// Hero home
.hero {
  overflow: hidden;
  color: $white;

  .content {
    background-position: left center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    video {
      position: absolute;
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: 1;
      display: none;
    }
  }

  .fixed-cover {
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  }
  &.hero_mobile .fixed-cover {
    background-attachment: initial !important;
  }
  &__cover {
    max-height: 900px;
    height: calc(100vh - 70px);
    min-height: auto;

    @include media-breakpoint-down(xs) {
      height: calc(100vh - 75px);
    }
  }

  // Home
  &__home {
    .title {
      line-height: 1.1;
      // font-size: $display4-size;
      font-size: 3.1rem;
      @include media-breakpoint-down(xs) {
        font-size: $h2-font-size;
      }
    }
    .subtitle {
      // font-size: $h2-font-size;
      font-size: 1.7rem;
      line-height: 1.2;
      @include media-breakpoint-down(md) {
        font-size: $h5-font-size;
      }
      @include media-breakpoint-down(xs) {
        font-size: $h5-font-size;
      }
    }
    & .container:after {
      @include media-breakpoint-down(xs) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        //background: linear-gradient(
        //  -90deg,
        //  rgba($white, 0.8) 20%,
        //  transparent 100%
        //);
      }
    }
  }

  // Full
  &__full {
    @include media-breakpoint-down(sm) {
      height: calc(100vh - 95px);

      &.compact {
        height: calc(100vh - 200px);
      }
    }
    .title {
      strong {
        @extend .prelo-bold-italic;
        display: block;
      }
      @include media-breakpoint-down(xs) {
        font-size: $h3-font-size;
      }
    }
    .subtitle {
      opacity: 0;
    }
    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: rgba($black, 0.7);
      top: 0;
      left: 0;
    }
    &-short {
      min-height: 500px;
      @include media-breakpoint-down(sm) {
        min-height: 350px;
      }
      .title {
        position: relative;
        @include media-breakpoint-down(sm) {
          font-size: $h3-font-size;
        }
        @include media-breakpoint-down(xs) {
          font-size: $h4-font-size;
          padding-left: 15px;
          padding-right: 15px;
        }
        strong {
          display: inline-block;
        }
      }
    }
  }

  // With contact form
  &__form {
    height: 100vh;
    max-height: 420px;
    min-height: 420px;

    .container {
      z-index: 2;
      position: relative;
    }

    @include media-breakpoint-down(sm) {
      height: calc(100vh - 95px);
      max-height: initial;

      &.compact {
        height: calc(100vh - 155px);
      }
    }
    .form-wrapper {
      max-width: 380px;
      width: 100%;
      height: 100%;
      color: #FFFFFF;
    }
    .banner-title {
      .logo {
        @include media-breakpoint-down(sm) {
          img {
            max-height: 55px;
          }
        }
      }
      .text {
        position: relative;
        padding-left: 27px;

        strong {
          @extend .prelo-bold;
          display: block;
        }
        &:after {
          position: absolute;
          top: 9px;
          left: 0px;
          background: $white;
          width: 2px;
          height: calc(100% - 1rem);
          content: '';
        }
      }
    }
    .sello {
      bottom: 0;
      img {
        max-height: 50px;
      }
      @include media-breakpoint-down(sm) {
        img {
          max-height: 30px;
        }
      }
    }
  }
}

.hero.has-video:not(.hero_mobile) .content {
  background: $black !important;
}

// Modal form
.info-modal-form {
  padding-top: 70px;
  padding-bottom: 70px;
}

// Brackets
.bracket {
  position: absolute;
  background: $white;
  width: 2px;
  height: calc(100% + 50px);
  top: -25px;
  transition-property: right, left, opacity;
  transition-duration: 0.4s, 0.4s;
  transition-timing-function: ease, ease-out;
  transition-delay: 0.6s, 0.6s, 0.6s;
  opacity: 0;
  &:after,
  &:before {
    content: '';
    width: 30px;
    height: 2px;
    background: $white;
    position: absolute;
  }
  &_left {
    left: -150px;
    .active & {
      left: -130px;
      opacity: 1;
    }
    @include media-breakpoint-down(md) {
      left: -120px;
      .active & {
        left: -100px;
      }
    }
    @include media-breakpoint-down(sm) {
      left: -60px;
      .active & {
        left: -40px;
      }
    }
    @include media-breakpoint-down(xs) {
      left: -20px;
      .active & {
        left: 0px;
      }
    }
    &:before {
      left: 0;
      top: 0;
    }
    &:after {
      left: 0;
      bottom: 0;
    }
  }
  &_right {
    right: -150px;
    .active & {
      right: -130px;
      opacity: 1;
    }
    @include media-breakpoint-down(md) {
      right: -120px;
      .active & {
        right: -100px;
      }
    }
    @include media-breakpoint-down(sm) {
      right: -60px;
      .active & {
        right: -40px;
      }
    }
    @include media-breakpoint-down(xs) {
      right: -20px;
      .active & {
        right: 0px;
      }
    }
    &:before {
      right: 0;
      top: 0;
    }
    &:after {
      right: 0;
      bottom: 0;
    }
  }
}
