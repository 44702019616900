//css sobre eclass
body.sobre-eclass {
  .team_name {
    span {
      &.prelo-semibold {
        color: #4b4b4b;
        font-weight: 200 !important;
      }
      &.text-secondary-primary.prelo-semibold {
        font-weight: 800 !important;
      }
    }    
  }
}