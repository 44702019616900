// INSTITUCIONES
// > DIPLOMADO DETALLE
// > CURSO DETALLE

.video-wrapper {
  background: $black;
  line-height: 1px;
  position: relative;

  &_info {
    background: #090806;
    height: 65px;
    margin-top: 0px;
    z-index: 5;
    position: relative;

    @include media-breakpoint-down(xs) {
      max-height: 40px;
    }

    .name {
      max-width: 160px;
    }
    .logo img {
      max-height: 25px;

      @include media-breakpoint-down(xs) {
        max-height: 15px;
      }
    }
  }
}

.features {
  font-size: 13px;

  .wrapper-icon {
    padding: 5px 5px 0;
    width: 60px;
    height: 60px;
    text-align: center;
  }
}

// .team {
//   &_icon {
//     width: 100px;
//     height: 100px;
//     background-color: $gray-400;
//   }

//   &_info {
//     font-size: 14px;
//   }

//   &_name {
//     margin-bottom: 15px;
//     padding-bottom: 1px;
//   }
// }

.modalidad {
  .name,
  p {
    font-size: 1.125rem;
  }
}

.title-circle {
  width: 170px;
  height: 170px;
  padding: 0 0 0 24px;
  background-color: $gray-600;
  font-size: 0.9375rem;

  span {
    @extend .sepline;
    @extend .pb-3;
  }
  strong {
    @extend .prelo-semibold;
    @extend .d-block;
  }
}

.diplocursos {
  margin-left: -5px;
  margin-right: -5px;

  .item {
    @extend .ease;
    position: relative;

    @include media-breakpoint-up(md) {
      width: 20%;
    }

    .content {
      font-size: 13px;
      background-color: $gray-300;
      min-height: calc(100% - 50px);

      &.has-pdf {
        padding-bottom: 60px !important;

        .btn {
          width: calc(100% - 40px);
          left: 20px;
          bottom: 70px;
          position: absolute;
          left: 20px;
          text-transform: capitalize !important;
        }
      }
    }

    .btn {
      white-space: initial;
      line-height: 1;
    }

    .download {
      @extend .ease;
      background-color: darken($gray-300, 10%);

      &:after {
        background-color: $primary;
        border-radius: 0;
      }

      &:hover {
        color: $white;

        &:after {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
    }

    &:hover {
      @include media-breakpoint-up(md) {
        transform: translate3d(0px, -15px, 0px);
      }
    }

    .feats {
      margin-bottom: -15px;

      li {
        border-top: 1px solid darken($border-color, 20%) !important;
      }
    }
  }
}
.video-wrapper {
  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    &::before {
      display: block;
      content: "";
    }

    .embed-responsive-item,
    iframe,
    embed,
    object,
    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  // .embed-responsive-21by9 {
  //   &::before {
  //     padding-top: 0;
  //   }
  // }

  // .embed-responsive-16by9 {
  //   &::before {
  //     padding-top: 0;
  //   }
  // }

  // .embed-responsive-4by3 {
  //   &::before {
  //     padding-top: 0;
  //   }
  // }

  // .embed-responsive-1by1 {
  //   &::before {
  //     padding-top: 0;
  //   }
  // }
}

.wp-video {
  position: absolute !important;
  top: 0 !important;
}
