// This a modified version of https://github.com/Robdel12/DropKick

// Mixins with weird names to avoid overwrites

@mixin dk-pre($property, $value, $prefixes) {
  @each $prefix in $prefixes {
    -#{$prefix}-#{$property}: $value;
  }
  #{$property}: $value;
}

@mixin dk-bxsz($box) {
  @include dk-pre(box-sizing, $box, webkit moz);
}

@mixin dk-txtflow($text) {
  @include dk-pre(text-overflow, $text, o);
}

$dk-border-color: #cccccc !default;
$dk-border-radius: 0;
$dk-disabled-color: #bbbbbb !default;

select {
  display: none;
}

.dk-select,
.dk-select *,
.dk-select *:before,
.dk-select *:after,
.dk-select-multi,
.dk-select-multi *,
.dk-select-multi *:before,
.dk-select-multi *:after {
  @include dk-bxsz(border-box);

  & ~ {
    display: block;
  }
}

.dk-select,
.dk-select-multi {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5em;
  width: 200px;
  cursor: pointer;
}

.dk-selected {
  @extend .prelo-medium;
  @extend .ease;
  width: 100%;
  height: 100%;
  display: flex;
  // white-space: nowrap;
  // overflow: hidden;
  position: relative;
  padding: 15px 30px 15px 15px;
  background: $gray-100;
  line-height: 1.1;
  // @include dk-txtflow(ellipsis);

  span {
    align-self: center
  }

  &.dk-link:hover {
    color: $body-color;
  }

  &.dk-link:before {
    transform: rotate(-90deg);
  }
  
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
  }
  &:before {
    font-family: icomoon;
    content: '\e900';
    top: 50%;
    margin: 0 10px 0 0;
    line-height: 2px;
    font-size: 25px;
    color: $primary;

    .dk-select-open-down & {
      content: '\e903';
    }
  }
}

.dk-selected-disabled {
  color: $dk-disabled-color;
}

.dk-select .dk-select-options {
  position: absolute;
  display: none;
  left: 0;
  right: 0;

  & .dk-option:first-child {
    display: none;
  }
}

.dk-select-open-up .dk-select-options {
  border-radius: $dk-border-radius $dk-border-radius 0 0;
  margin-bottom: -1px;
  bottom: 100%;
}

.dk-select-open-down .dk-select-options {
  border-radius: 0 0 $dk-border-radius $dk-border-radius;
  margin-top: -1px;
  top: 100%;
}

.dk-select-multi .dk-select-options {
  max-height: 10em;
}

.dk-select-options {
  background-color: $gray-400;
  @extend .prelo-medium;
  list-style: none;
  margin: 0;
  max-height: 10.5em;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 0;
  width: auto;
  z-index: 100;
}

.dk-option-selected {
  background-color: $primary;
  color: $white;

  &:after {
    display: none;
  }
}

.dk-select-options-highlight .dk-option-selected {
  background-color: transparent;

  &:after {
    display: block;
  }
}

.dk-option {
  @extend .ease;
  padding: 15px 15px;
  font-size: 14px;
  color: $white;
  position: relative;
  line-height: 1.4;

  &:after {
    content: '';
    background: rgba($white, 0.4);
    width: calc(100% - 20px);
    height: 1px;
    position: absolute;
    top: -1px;
    left: 10px;
  }

  // &:last-child {

  //   &:after {
  //     display: none;
  //   }
  // }
}

.dk-select-options .dk-option-highlight {
  background-color: $primary;
}

.dk-select-options .dk-option-disabled {
  color: $dk-disabled-color;
  background-color: transparent;
}

.dk-select-options .dk-option-hidden {
  display: none;
}

.dk-optgroup {
  border-width: 1px 0;
  padding: 0.25em 0;
  margin-top: 0.25em;
  + .dk-option {
    margin-top: 0.25em;
  }
  + .dk-optgroup {
    border-top-width: 0;
    margin-top: 0;
  }
  &:nth-child(2) {
    padding-top: 0;
    border-top: none;
    margin-top: 0;
  }
  &:last-child {
    border-bottom-width: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.dk-optgroup-disabled {
  opacity: 0.6;
  color: $dk-disabled-color;
  cursor: not-allowed;
}

.dk-optgroup-label {
  padding: 0 0.5em 0.25em;
  font-weight: bold;
  width: 100%;
}

.dk-optgroup-options {
  list-style: none;
  padding-left: 0;
  li {
    padding-left: 1.2em;
  }
}

.dk-select-open-up .dk-selected:before,
.dk-select-open-down .dk-selected:before {
  border-width: 0 0.25em 0.25em;
}

.dk-select-open-up .dk-select-options,
.dk-select-open-down .dk-select-options,
.dk-select-multi:focus .dk-select-options {
  display: block;
}

.dk-select-multi:hover,
.dk-select-multi:focus {
  outline: none;
}

.dk-selected:hover,
.dk-selected:focus {
  background: darken($gray-300, 7%);
  outline: none;
}

.dk-select-disabled {
  opacity: 0.6;
  color: $dk-disabled-color;
  cursor: not-allowed;
  .dk-selected {
    &:hover,
    &:focus {
      border-color: inherit;
      &:before {
        border-top-color: inherit;
      }
      &:after {
        border-left-color: inherit;
      }
    }
  }
}

select[data-dkcacheid] {
  display: none;
}
.embed-responsive-16by9{
  :before {
    padding-top: 0 !important; 
  }
}
