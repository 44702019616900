// INSTITUCIONES
// > HOME
// > DIPLOMADOS
// > CURSOS

.stripe {
  &-primary {
    background-color: $primary;
    color: $primary;

    .background {
      background-color: $primary;
    }
  }

  [class*='btn-'] {
    color: $white;

    span {
      @extend .ease;
    }

    &:hover,
    &:focus {
      background: transparent;
      box-shadow: inset 0 0 0 2px $white;
    }
  }

  .h4 {
    @extend .mt-0;
    @extend .mb-0;
    @extend .pb-3;
  }

  strong {
    @extend .prelo-semibold;
    @extend .d-block;
  }

  .background {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;

    .content {
      z-index: 2;
      position: relative;
    }

    &:after,
    &:before {
      display: block;
      position: absolute;
      top: 0;
      height: 100%;
      width: 50%;
      content: '';
      pointer-events: none;
      z-index: 1;
    }
    &:after {
      right: 0;
      background-image: linear-gradient(90deg, transparent, currentColor 100%);
    }
    &:before {
      left: 0;
      background-image: linear-gradient(-90deg, transparent, currentColor 100%);
    }
  }

  .btn.fill-hover {
    &:after {
      background: $white;
    }
    &:hover span {
      color: $body-color;
    }
  }

  &-primary {
    background: $gray-100;
  }
}

.content-page {
  overflow: hidden;
}

.link-icon {
  i {
    top: 1px;
    position: relative;
    display: inline-block;
  }

  &:hover {
    i {
      -webkit-transform: translateX(10px);
      transform: translateX(10px);
    }
  }
}

.strong-block {
  strong {
    display: block;
  }
}

.list-images {
  img {
    max-width: 120px;
    max-height: 50px;
  }

  &_sobre {
    // opacity: 0;

    .item {
      &:not(:last-child) {
        padding-right: 110px;
        background: url('../images/lineasobre.png') right 25px top 40px
          no-repeat;

        @include media-breakpoint-down(sm) {
          padding-right: 20px;
          padding-left: 20px;
          margin-right: 0;
          background: none;
        }
      }

      @include media-breakpoint-down(sm) {
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    .image-wrapper {
      max-width: 85px;
      height: 60px;
    }

    .counter {
      width: 7.6rem;
      opacity: 0;
      letter-spacing: -1pt;
      font-size: 58px;

      @include media-breakpoint-down(md) {
        width: 5rem;
      }
      @include media-breakpoint-down(sm) {
        font-size: 3rem;
      }
    }

    .descripcion {
      line-height: 1.4;
      display: block;
      height: 30px;
      font-size: 14px;
    }
  }
}